
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.t {
  width: 100%;
  overflow-x: auto;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 800px;
  padding: 50px;
  .top-row-actions {
    display: flex;
    align-items: flex-end;
    margin-bottom: 21px;

    .g-button {
      padding: 8px 25px;
      margin: 0 5px;
    }
  }
  .search {
    min-width: 245px;
    padding: 0;
    margin-left: auto;
    ::v-deep {
      .input-element {
        padding: 10px 0;
      }
    }
  }
}
.table {
  width: 100%;
  margin-bottom: 50px;
  overflow-x: auto;

  &__pagination {
    margin: 0 auto;
    margin-top: auto;
  }
}
